import { useMemo } from "react";
import { hash } from "object-code";

export interface ListModelItem {
  data: any;
}

export class ListModel<T extends ListModelItem> {
  public readonly items: T[];
  public readonly isEmpty: boolean;
  private readonly cacheKey: string;

  public constructor(items: T[]) {
    this.items = items;
    this.isEmpty = items.length === 0;
    this.cacheKey = this.items.map((i) => hash(i.data)).join(">");
  }

  public useItems(): T[] {
    return useMemo(() => this.items, [this.cacheKey]);
  }
}

export default ListModel;
