import { DateTime } from "luxon";
import { MySqlDatabaseApiData } from "../../model/database/MySqlDatabase";
import { MySqlUserApiData } from "../../model/database/MySqlUser";
import { MySqlVersionApiData } from "../../model/database/MySqlVersion";
import { RedisDatabaseApiData } from "../../model/database/RedisDatabase";
import { RedisVersionApiData } from "../../model/database/RedisVersion";
import { demoDate } from "../staticDemoData";

export const demoMySqlDatabase: MySqlDatabaseApiData = {
  characterSettings: {
    characterSet: "utf8mb4",
    collation: "utf8mb4_general_ci",
  },
  createdAt: demoDate,
  description: "Demo MySQL Datenbank",
  finalizers: ["app:appinstallation:1"],
  hostname: "mysql-123456.db.project.host",
  id: "1",
  isReady: true,
  isShared: false,
  name: "mysql_1234456",
  projectId: "1",
  updatedAt: demoDate,
  version: "8.0",
  storageUsageInBytes: 312421312,
  storageUsageInBytesSetAt: demoDate,
  status: "ready",
  statusSetAt: DateTime.now().toISO(),
};

export const demoMySqlDatabaseUser: MySqlUserApiData = {
  accessIpMask: "",
  accessLevel: "full",
  createdAt: demoDate,
  databaseId: "1",
  description: "",
  disabled: false,
  externalAccess: false,
  id: "1",
  mainUser: true,
  name: "dbu_123456",
  passwordUpdatedAt: demoDate,
  updatedAt: demoDate,
};

export const demoRedisDatabase: RedisDatabaseApiData = {
  configuration: {
    maxMemory: "1000",
    maxMemoryPolicy: "noeviction",
    persistent: true,
  },
  createdAt: demoDate,
  description: "Demo Redis Datenbank",
  hostname: "redis-123456.db.project.host",
  id: "2",
  name: "redis_123456",
  port: 1234,
  projectId: "1",
  updatedAt: demoDate,
  version: "7.0",
  finalizers: ["app:appinstallation:1"],
  storageUsageInBytes: 312421312,
  storageUsageInBytesSetAt: demoDate,
  status: "ready",
  statusSetAt: DateTime.now().toISO(),
};

export const demoMySqlVersion: MySqlVersionApiData = {
  id: "mysql80",
  name: "MySQL 8.0",
  number: "8.0",
  disabled: false,
};

export const demoRedisVersion: RedisVersionApiData = {
  id: "redis7",
  name: "Stable 7.0",
  number: "7.0",
  disabled: false,
};
