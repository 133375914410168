import { HorizontalSwitchSlider } from "@mittwald/flow-components/dist/components/HorizontalSwitchSlider";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import React, { FC } from "react";
import { iconInfoCircle } from "@mittwald/flow-icons/dist/infoCircle";
import { SpaceServerArticle } from "../../../../../../model/article";
import { useBuildStorageSwitchSliderItems } from "../../../hooks/useBuildStorageSwitchSliderItems";

export interface StorageSectionProps {
  article: SpaceServerArticle;
}

export const StorageSection: FC<StorageSectionProps> = (props) => {
  const { article } = props;

  const options = useBuildStorageSwitchSliderItems(article);

  return (
    <Section.Item headline="serverStorage">
      <HorizontalSwitchSlider
        items={options}
        name="storageSizeInBytes"
        showInitialIndicator
      />
      <TextWithIconLayout icon={iconInfoCircle}>
        <Text i18n="serverStorageAdditionalInfo" small squished />
      </TextWithIconLayout>
    </Section.Item>
  );
};

export default StorageSection;
