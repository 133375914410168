import MetricsQueryResponse from "../metrics/MetricsQueryResponse";
import { Metrics, MetricsRequestOptions } from "../misc/Metrics";
import { SpaceServerProject } from "./";

export class SpaceServerProjectMetrics {
  public readonly project: SpaceServerProject;
  private readonly projectShortId: string;
  private readonly serverShortId?: string;

  private constructor(project: SpaceServerProject) {
    this.project = project;
    this.projectShortId = project.shortId;
    this.serverShortId = project.data.serverShortId;
  }

  public static of(project: SpaceServerProject): SpaceServerProjectMetrics {
    return new SpaceServerProjectMetrics(project);
  }

  public useCpuUsage(options: MetricsRequestOptions): MetricsQueryResponse {
    return Metrics.executeRequest(
      `
      SUM (cloudhosting_project_usage_cpu_seconds_total_rate:1m{project="${this.projectShortId}"}) / 
      SUM (cloudhosting_placementgroup_limits_cpu_seconds:1m{placement_group="${this.serverShortId}"})
      `,
      options,
    );
  }

  public useMemoryUsage(options: MetricsRequestOptions): MetricsQueryResponse {
    return Metrics.executeRequest(
      `
      SUM (cloudhosting_project_usage_memory_bytes:1m:max{project="${this.projectShortId}"}) / 
      SUM (cloudhosting_placementgroup_limits_memory_bytes:1m{placement_group="${this.serverShortId}"})
      `,
      options,
    );
  }

  public useDbStorageUsage(): MetricsQueryResponse {
    return Metrics.executeRequest(
      `SUM(cloudhosting_project_usage_database_storage_bytes:5m:max{project="${this.projectShortId}"}) or vector (0)`,
    );
  }

  public useWebStorageUsage(): MetricsQueryResponse {
    return Metrics.executeRequest(
      `cloudhosting_project_usage_web_storage_bytes:5m:max{project="${this.projectShortId}"}`,
    );
  }

  public useMailStorageUsage(): MetricsQueryResponse {
    return Metrics.executeRequest(
      `SUM(project_usage_mail_storage_bytes{project="${this.projectShortId}"}) or vector (0)`,
    );
  }
}
