import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import Customer from "./Customer";
import assertStatus from "@mittwald/api-client/dist/types/assertStatus";

export class CustomerList extends ListModel<Customer> {
  public static async getAll(): Promise<CustomerList> {
    const customersResponse = await mittwaldApi.customerListCustomers.request(
      {},
    );

    assertStatus(customersResponse, 200);

    return new CustomerList(
      customersResponse.content.map((c) => Customer.fromApiData(c)),
    );
  }

  public static useAll(): CustomerList {
    const customers = mittwaldApi.customerListCustomers
      .getResource({})
      .useWatchData()
      .map((c) => Customer.fromApiData(c));

    return new CustomerList(customers);
  }

  public static tryUseAll(): CustomerList {
    const customers = mittwaldApi.customerListCustomers
      .getResource({})
      .useWatchData({ optional: true });

    const customerList = customers
      ? customers.map((c) => Customer.fromApiData(c))
      : [];

    return new CustomerList(customerList);
  }

  public getSelectOptions(optional?: boolean): SelectOptions {
    const options: SelectOptions = this.items
      .sort((a, b) => a.data.name.localeCompare(b.data.name))
      .map((customer) => {
        return {
          value: customer.data.customerId,
          label: { text: customer.data.name },
        };
      });

    if (optional) {
      options.unshift({ label: "nothingSelected", value: undefined });
    }

    return options;
  }

  public getCustomersAllowedToOrder(): Customer[] {
    return this.items.filter((customer) => customer.isAllowedToOrder);
  }

  public tryGetById(customerId?: string): Customer | undefined {
    return this.items.find((c) => c.id === customerId);
  }
}
