import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout";
import { FormController } from "@mittwald/flow-components/dist/components/Form";
import LoadingView from "@mittwald/flow-components/dist/components/LoadingView";
import { Section } from "@mittwald/flow-components/dist/components/Section";
import { Select } from "@mittwald/flow-components/dist/components/Select";
import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import SelectBox from "@mittwald/flow-components/dist/components/SelectBox";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { WizardStep } from "@mittwald/flow-components/dist/components/Wizard/components/WizardStep";
import { ValueControllerInstance } from "@mittwald/flow-components/dist/hooks/useValueController";
import Suspense from "@mittwald/flow-lib/dist/resources/Suspense";
import React, { FC } from "react";
import VirtualPageViewTracking from "../../../../../../widgets/components/tracking/components/VirtualPageViewTracking";
import { CustomerStepFields } from "../../../types";

interface Props {
  customerModeController: ValueControllerInstance<string>;
  existingCustomersAvailable: boolean;
  customerMode: string;
  customerSelectOptions: SelectOptions;
  trackingId?: string;
  form: FormController<CustomerStepFields>;
  noReturn?: boolean;
}

export const CustomerStep: FC<Props> = (props) => {
  return (
    <TranslationProvider name="customerStepContent" type="component">
      <WizardStep
        form={props.form}
        id="customer"
        indicatorText="customerStep"
        noReturn={props.noReturn}
      >
        <Suspense loadingView={<LoadingView _height={250} />}>
          {props.trackingId && (
            <VirtualPageViewTracking
              identifier={props.trackingId}
              name="checkout/organisation"
            />
          )}
          <Section.Item headline="customer">
            <Text i18n="customerDescription" />
            <SelectBox.View
              controller={props.customerModeController}
              options={[
                {
                  value: "existing",
                  title: "existingCustomer",
                  disabled: !props.existingCustomersAvailable,
                },
                { value: "new", title: "newCustomer" },
              ]}
            />
            <ColumnLayout medium={[1, 1]}>
              {props.customerMode === "new" && (
                <TextField
                  autoFocus
                  label="name"
                  name="name"
                  rules={{ required: true }}
                />
              )}
              {props.customerMode === "existing" && (
                <Select
                  label="customer"
                  name="id"
                  options={props.customerSelectOptions}
                  placeholder="customer"
                  rules={{
                    required: true,
                  }}
                />
              )}
            </ColumnLayout>
          </Section.Item>
        </Suspense>
      </WizardStep>
    </TranslationProvider>
  );
};
