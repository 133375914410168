import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC } from "react";

interface Props {
  name?: string;
  required?: boolean;
}

export const FirstNameInput: FC<Props> = (props) => {
  return (
    <TextField
      autoComplete="given-name"
      label="firstName"
      name={props.name ?? "firstName"}
      rules={{
        required: !!props.required,
        validate: () => true,
      }}
    />
  );
};

export default FirstNameInput;
