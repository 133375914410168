import { SelectOptions } from "@mittwald/flow-components/dist/components/Select/types";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { useMemo } from "react";
import { mittwaldApi } from "../../api/Mittwald";
import ListModel from "../misc/ListModel";
import AppInstallation from "./AppInstallation";

export class AppInstallationList extends ListModel<AppInstallation> {
  public findById(id?: string): AppInstallation | undefined {
    return this.items.find((i) => i.id === id);
  }

  public static useLoadAllByProjectId(projectId: string): AppInstallationList {
    const appInstallations = mittwaldApi.appListAppinstallations
      .getResource({
        path: { projectId },
      })
      .useWatchData();

    const appInstallationsMemo = useMemo(
      () => appInstallations.map((a) => AppInstallation.fromApiData(a)),
      [appInstallations],
    );

    return new AppInstallationList(appInstallationsMemo);
  }

  public static useTryLoadAllByProjectId(
    projectId?: string,
  ): AppInstallationList | undefined {
    const appInstallations = mittwaldApi.appListAppinstallations
      .getResource(
        projectId
          ? {
              path: { projectId },
            }
          : null,
      )
      .useWatchData();

    const appInstallationsMemo = useMemo(
      () =>
        appInstallations
          ? appInstallations.map((a) => AppInstallation.fromApiData(a))
          : [],
      [appInstallations],
    );

    return appInstallations
      ? new AppInstallationList(appInstallationsMemo)
      : undefined;
  }

  public static useLoadAllByPathParam(): AppInstallationList {
    const { projectId } = usePathParams("projectId");
    return AppInstallationList.useLoadAllByProjectId(projectId);
  }

  public static useLoadAll(): AppInstallationList {
    const appInstallations = mittwaldApi.appListAppinstallationsForUser
      .getResource({})
      .useWatchData();

    const appInstallationsMemo = useMemo(
      () => appInstallations.map((a) => AppInstallation.fromApiData(a)),
      [appInstallations],
    );

    return new AppInstallationList(appInstallationsMemo);
  }

  public getSelectOptions(optional?: boolean): SelectOptions {
    const options: SelectOptions = this.items
      .sort((a, b) => a.data.description.localeCompare(b.data.description))
      .map((appInstallation) => {
        return {
          value: appInstallation.data.id,
          label: { text: appInstallation.data.description },
        };
      });

    if (optional) {
      options.unshift({ label: "nothingSelected", value: undefined });
    }

    return options;
  }
}
