import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC } from "react";

interface Props {
  name?: string;
}

export const CityInput: FC<Props> = (props) => {
  return (
    <TextField
      autoComplete="address-level2"
      label="city"
      name={props.name ?? "city"}
      rules={{ required: true }}
    />
  );
};

export default CityInput;
