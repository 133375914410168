import { faArrowUpArrowDown } from "@fortawesome/pro-regular-svg-icons/faArrowUpArrowDown";
import { faBoxesStacked } from "@fortawesome/pro-regular-svg-icons/faBoxesStacked";
import { faChartLineUp } from "@fortawesome/pro-regular-svg-icons/faChartLineUp";
import { CheckBox } from "@mittwald/flow-components/dist/components/CheckBox";
import { useFormContext } from "@mittwald/flow-components/dist/components/Form";
import { H2 } from "@mittwald/flow-components/dist/components/H2";
import { InfoBox } from "@mittwald/flow-components/dist/components/InfoBox";
import Skeleton from "@mittwald/flow-components/dist/components/Skeleton";
import { TranslationProvider } from "@mittwald/flow-components/dist/components/TranslationProvider";
import { iconContract } from "@mittwald/flow-icons/dist/contract";
import { iconInvoice } from "@mittwald/flow-icons/dist/invoice";
import { DateTime } from "luxon";
import React, { FC } from "react";
import { Box } from "@mittwald/flow-components/dist/components/Box";
import DisableForm from "../../../../../../components/DisableForm";
import { SpaceServerArticle } from "../../../../../../model/article";
import Bytes from "../../../../../../model/misc/Bytes";
import Order from "../../../../../../model/order/Order";
import { PromoCode } from "../../../../../../widgets/utils/promoCode";
import { AdjustStorageFormFields } from "../../../types";
import PerMonthWithoutFreeTrialText from "./PerMonthWithoutFreeTrialText";

interface Props {
  article: SpaceServerArticle;
  freeTrial: boolean;
  promotion?: PromoCode;
}

export const OrderServerPricePreviewBox: FC<Props> = (props) => {
  const { article, freeTrial, promotion } = props;

  const form = useFormContext<AdjustStorageFormFields>();

  const storageSize = Bytes.of(form.watch("storageSizeInBytes"), "byte");

  return (
    <TranslationProvider name="previewBoxSpaceServer" type="component">
      <Box _flexDirection="column" _itemGap="m" _py="m">
        <InfoBox.InfoItem icon={faBoxesStacked} text="resources" />
        <InfoBox.InfoItem icon={faChartLineUp} text="performance" />
        <InfoBox.InfoItem icon={iconInvoice} text="invoice" />
        <InfoBox.InfoItem icon={faArrowUpArrowDown} text="configuration" />
        <InfoBox.InfoItem icon={iconContract} text="termination" />
        <Box _alignItems="center" _flexDirection="column" _itemGap="m" _mt="m">
          <InfoBox.Price
            price={{
              useValue: () => {
                const moneyValue = article
                  .useOrderPreview({
                    storageSize: storageSize,
                  })
                  .total.toMoneyValueInput();
                return freeTrial || promotion ? 0 : moneyValue;
              },
              loadingView: (
                <DisableForm>
                  <Skeleton _width={140} />
                </DisableForm>
              ),
            }}
            priceSubline={
              freeTrial || promotion ? (
                <PerMonthWithoutFreeTrialText
                  freeTrialUntil={
                    promotion
                      ? DateTime.now().plus({ day: promotion.promotionDays })
                      : Order.freeTrialUntil
                  }
                  price={{
                    useValue: () =>
                      article
                        .useOrderPreview({
                          storageSize: storageSize,
                        })
                        .total.toMoneyValueInput(),
                    loadingView: (
                      <DisableForm>
                        <Skeleton _width={140} />
                      </DisableForm>
                    ),
                  }}
                />
              ) : (
                "perMonth"
              )
            }
          />
          {!promotion && <CheckBox name="useFreeTrial" title="freeTrial" />}
          {promotion && (
            <H2
              i18n={{
                id: "promotion",
                values: { days: promotion.promotionDays },
              }}
            />
          )}
        </Box>
      </Box>
    </TranslationProvider>
  );
};

export default OrderServerPricePreviewBox;
