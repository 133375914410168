import { DateTime } from "luxon";
import {
  ProjectApiData,
  ProjectInviteApiData,
  ProjectMembershipApiData,
} from "../../model/project";
import { demoDate, demoEmail, demoName, demoShortId } from "../staticDemoData";

export const demoProject: ProjectApiData = {
  id: "1",
  shortId: demoShortId,
  readiness: "ready",
  description: "Demo Projekt",
  customerId: "1",
  serverId: "1",
  createdAt: demoDate,
  directories: [],
  enabled: true,
  isReady: true,
  clusterID: "alderaan",
  clusterDomain: "project.host",
  serverShortId: demoShortId,
  status: "ready",
  statusSetAt: DateTime.now().toISO(),
};

export const demoProjectMembership: ProjectMembershipApiData = {
  projectId: "1",
  id: "1",
  role: "owner",
  inherited: true,
  inviteId: "1",
  userId: "1",
  memberSince: demoDate,
  mfa: true,
  email: "testmail@gmail.com",
};

export const demoProjectInvite: ProjectInviteApiData = {
  id: "1",
  projectId: "1",
  role: "owner",
  information: {
    invitedBy: demoName,
    userId: "1",
    invitationToken: "1",
  },
  mailAddress: demoEmail,
  projectDescription: "Demo Projekt",
};
