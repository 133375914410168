import { Select } from "@mittwald/flow-components/dist/components/Select";
import React, { FC } from "react";
import SalutationUI from "../../../model/ui/misc/SalutationUI";

interface Props {
  name?: string;
}

export const SalutationSelect: FC<Props> = (props) => {
  return (
    <Select
      label="salutation"
      name={props.name ?? "salutation"}
      options={SalutationUI.salutationSelectOptions()}
      rules={{ required: true }}
    />
  );
};
