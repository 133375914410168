import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC } from "react";

interface Props {
  name?: string;
  required?: boolean;
}

export const LastNameInput: FC<Props> = (props) => {
  return (
    <TextField
      autoComplete="family-name"
      label="lastName"
      name={props.name ?? "lastName"}
      rules={{
        required: !!props.required,
        validate: () => true,
      }}
    />
  );
};

export default LastNameInput;
