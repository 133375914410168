import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC } from "react";

interface Props {
  name?: string;
}

export const PostalCodeInput: FC<Props> = (props) => {
  return (
    <TextField
      autoComplete="postal-code"
      label="zip"
      name={props.name ?? "zip"}
      rules={{ required: true }}
    />
  );
};

export default PostalCodeInput;
