import { validatePhoneNumber } from "@mittwald/flow-components/dist/components/PhoneNumber/validation";
import { TextField } from "@mittwald/flow-components/dist/components/TextField";
import React, { FC } from "react";
import { Validate } from "react-hook-form";

interface Props {
  additionalValidations?:
    | Validate<any, any>
    | Record<string, Validate<any, any>>;
  autoFocus?: boolean;
  name: string;
}

export const PhoneNumberInput: FC<Props> = (props) => {
  const { additionalValidations, name, autoFocus = false } = props;
  return (
    <TextField
      autoFocus={autoFocus}
      label="phoneNumber"
      name={name}
      placeholder={{ text: "+49 5772 293 100" }}
      rules={{
        required: true,
        validate: {
          ...additionalValidations,
          hasCountryCode: (number: string) => {
            return number.startsWith("+");
          },
          phone: validatePhoneNumber,
        },
      }}
    />
  );
};

export default PhoneNumberInput;
