import { faTruck } from "@fortawesome/pro-regular-svg-icons/faTruck";
import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { BadgeSelectionListItem } from "@mittwald/flow-components/dist/components/BadgeSelectionList";
import { PrimaryStatusIcon } from "@mittwald/flow-components/dist/components/ResourceList";
import { ResourceRowState } from "@mittwald/flow-components/dist/components/ResourceList/hooks/useResourceList/types";
import { SelectBoxOptions } from "@mittwald/flow-components/dist/components/SelectBox";
import { I18nDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { iconDomain } from "@mittwald/flow-icons/dist/domain";
import { iconIngress } from "@mittwald/flow-icons/dist/ingress";
import { iconSubdomain } from "@mittwald/flow-icons/dist/subdomain";
import { isDomain } from "@mittwald/flow-lib/dist/validation/domain";
import { ValidateResult } from "react-hook-form";
import { mittwaldApi, MittwaldApi } from "../../../api/Mittwald";
import { HeavyListRecords } from "../../../components/Form/HeavyListFieldArrayContainer";
import { validateJsonSchema } from "../../../lib/jsonSchema";
import { AppInstallationList } from "../../app/AppInstallationList";
import { ContainerList } from "../../container/ContainerList";
import { Domain, DomainTargetInputs } from "../../domain/Domain";
import DomainName from "../../domain/DomainName";
import { GeneratedDomain } from "../../domain/GeneratedDomains";
import { Ingress, IngressTargetApiData } from "../../domain/Ingress";
import { IngressList } from "../../domain/IngressList";
import { IngressPathApiData } from "../../domain/IngressPath";
import DomainPrice from "../../domain/prices/DomainPrice";
import { TldList } from "../../domain/TldList";
import UserInput from "../../misc/userInput/UserInput";
import { ProjectMembership } from "../../project";

export enum DomainTypes {
  bookDomain = "bookDomain",
  moveDomain = "moveDomain",
  vHost = "vHost",
  subdomain = "subdomain",
}

export enum TargetTypes {
  directory = "directory",
  url = "url",
  app = "app",
  default = "default",
  container = "container",
}

export enum DomainInputType {
  enterDomain = "enterDomain",
  generateDomain = "generateDomain",
}

export const targetTypes: TargetTypes[] = [
  TargetTypes.app,
  TargetTypes.container,
  TargetTypes.directory,
  TargetTypes.url,
  TargetTypes.default,
];

export type NameServerType = "internal" | "external";

export const nameServerTypes: NameServerType[] = ["internal", "external"];

export interface AuthCodeValidationResult {
  isValid: boolean;
  error?: string;
}

export class DomainUI {
  private readonly domain: Domain;

  public static domainGeneratorTlds = [
    "de",
    "com",
    "at",
    "eu",
    "net",
    "info",
    "org",
    "ch",
    "biz",
  ];

  public static readonly nameServerTypeOptions: SelectBoxOptions =
    nameServerTypes.map((nameServerType) => ({
      title: nameServerType,
      value: nameServerType,
    }));

  public constructor(domain: Domain) {
    this.domain = domain;
  }

  public static useGeneratorTldBadges(): BadgeSelectionListItem[] {
    return this.domainGeneratorTlds.map((tld) => ({
      value: tld,
      label: {
        text: `.${tld}`,
      },
    }));
  }

  public static useTargetTypeOptions(
    projectId: string,
    isAllowedToUseDirectoryTargets: boolean = false,
    isAllowedToUseContainerTargets: boolean = false,
  ): SelectBoxOptions {
    const appInstallations =
      AppInstallationList.useLoadAllByProjectId(projectId);
    const containers = ContainerList.useLoadAllByProjectId(projectId);

    return targetTypes
      .filter(
        (t) =>
          (isAllowedToUseDirectoryTargets ? true : t !== "directory") &&
          (isAllowedToUseContainerTargets ? true : t !== "container"),
      )
      .map((targetType) => ({
        title: targetType,
        value: targetType,
        disabled:
          (targetType === "app" && appInstallations.isEmpty) ||
          (targetType === "container" && containers.isEmpty),
      }));
  }
  public static getGeneratedDomainsOptions(
    generatedDomains: GeneratedDomain[],
    prices: DomainPrice[],
    mapAdditionalInfo: (price: number) => I18nDefinition,
  ): SelectBoxOptions {
    return generatedDomains.map((generated) => {
      const { domain, available } = generated;
      const tld = DomainName.parse(domain).tld;
      const price = prices.find((item) => item.tld == tld && item.mStudio);

      const additionalInfo = price
        ? {
            i18n: available
              ? mapAdditionalInfo(price.startPrice * 12)
              : "domainNotAvailable",
            small: true,
          }
        : undefined;

      return {
        value: domain,
        title: { text: "" },
        description: { text: domain },
        disabled: !available,
        additionalInfo,
      };
    });
  }

  public static getTargetValue(
    targetType: TargetTypes,
    values: DomainTargetInputs,
  ): IngressPathApiData["target"] {
    return targetType === TargetTypes.default
      ? { useDefaultPage: true }
      : targetType === TargetTypes.directory
        ? { directory: values.directory }
        : targetType === TargetTypes.url
          ? { url: values.url }
          : targetType === TargetTypes.container
            ? {
                container: {
                  id: values.container,
                  portProtocol: values.containerPort,
                },
              }
            : { installationId: values.installationId };
  }

  public static isDefaultTargetType(
    value?: IngressTargetApiData,
  ): value is MittwaldApi.Components.Schemas.De_Mittwald_V1_Ingress_TargetUseDefaultPage {
    return !!(value && "useDefaultPage" in value);
  }

  public static isDirectoryTargetType(
    value?: IngressTargetApiData,
  ): value is MittwaldApi.Components.Schemas.De_Mittwald_V1_Ingress_TargetDirectory {
    return !!(value && "directory" in value);
  }

  public static isUrlTargetType(
    value?: IngressTargetApiData,
  ): value is MittwaldApi.Components.Schemas.De_Mittwald_V1_Ingress_TargetUrl {
    return !!(value && "url" in value);
  }

  public static isAppTargetType(
    value?: IngressTargetApiData,
  ): value is MittwaldApi.Components.Schemas.De_Mittwald_V1_Ingress_TargetInstallation {
    return !!(value && "installationId" in value);
  }

  public static isContainerTargetType(
    value?: IngressTargetApiData,
  ): value is MittwaldApi.Components.Schemas.De_Mittwald_V1_Ingress_TargetContainer {
    return !!(value && "container" in value);
  }

  public static getTargetType(value?: IngressTargetApiData): TargetTypes {
    if (this.isAppTargetType(value)) {
      return TargetTypes.app;
    } else if (this.isUrlTargetType(value)) {
      return TargetTypes.url;
    } else if (this.isDirectoryTargetType(value)) {
      return TargetTypes.directory;
    } else if (this.isContainerTargetType(value)) {
      return TargetTypes.container;
    } else {
      return TargetTypes.default;
    }
  }

  public static isAllowedToUseDirectoryTargets(ingress: Ingress): boolean {
    return DomainUI.getTargetType(ingress.baseTarget) === TargetTypes.directory;
  }

  public static getPrimaryStatusIcon(
    domain: Domain,
  ): PrimaryStatusIcon | undefined {
    if (domain.processes.isAuthCodeMismatchError()) {
      return {
        tooltip: "domainAuthInfoNotMatch",
        type: "error",
      };
    }
    if (domain.processes.hasFailedTransfer()) {
      return {
        tooltip: "transferFailed",
        type: "error",
      };
    }
    if (domain.processes.containsDeclareRequested()) {
      if (domain.processes.hasFailedProcess()) {
        return { tooltip: "registrationFailed", type: "error" };
      }
      return {
        tooltip: "registrationPending",
        type: "process",
      };
    }
    return undefined;
  }

  public static getResourceRowState(domain: Domain): ResourceRowState {
    if (domain.processes.isEmpty) {
      return domain.ready
        ? ResourceRowState.DEFAULT
        : ResourceRowState.DISABLED;
    }

    if (
      domain.processes.containsDeclareRequested() &&
      !domain.processes.isAuthCodeMismatchError()
    ) {
      return domain.processes.hasFailedTransfer()
        ? ResourceRowState.DEFAULT
        : ResourceRowState.DISABLED;
    }
    return ResourceRowState.DEFAULT;
  }

  public static useTypeSelectBoxOptions(projectId: string): SelectBoxOptions {
    const currentRole = ProjectMembership.useLoadOwn(projectId);
    const accessibleIngresses =
      IngressList.useLoadAllAccessible().useEnabledIngressList();
    const hasAccessibleIngresses = accessibleIngresses.items.length > 0;

    return [
      {
        disabled: !currentRole.role.is("owner"),
        value: DomainTypes.bookDomain,
        title: "bookDomain",
        description: "bookDomainDescription",
        icon: iconDomain,
        additionalInfo: { i18n: "price" },
      },
      {
        disabled: !currentRole.role.is("owner"),
        value: DomainTypes.moveDomain,
        title: "moveDomain",
        description: "moveDomainDescription",
        icon: faTruck,
        additionalInfo: { i18n: "price" },
      },
      {
        value: DomainTypes.vHost,
        title: "vHost",
        description: "vHostDescription",
        icon: iconIngress,
        additionalInfo: { i18n: "free" },
      },
      {
        value: DomainTypes.subdomain,
        title: "subdomain",
        description: "subdomainDescription",
        icon: iconSubdomain,
        additionalInfo: { i18n: "free" },
        disabled: !hasAccessibleIngresses,
      },
    ];
  }

  public static of(data: Domain): DomainUI {
    return new DomainUI(data);
  }

  public getNameServer(): HeavyListRecords {
    return this.domain.isInternalDomainWithInternalNameServers
      ? []
      : this.domain.nameservers.map((n) => ({
          value: n,
        }));
  }

  public static async isDomainValidAndAvailable(
    domain: string,
  ): Promise<"noDomain" | "unavailable" | "available"> {
    if (!isDomain(domain)) {
      return "noDomain";
    }
    const promptDomainIsAvailable = await Domain.isAvailable(domain);
    const tldAvailable = (await TldList.getTldByDomain(domain)) != undefined;
    if (promptDomainIsAvailable === "available" && tldAvailable) {
      return "available";
    }
    return "unavailable";
  }

  public static async validateDomainForOrder(
    domain: string,
  ): Promise<ValidateResult> {
    if (!isDomain(domain)) {
      return "validDomain";
    }

    if (!(await TldList.getTldByDomain(domain))) {
      return "validDomainTld";
    }

    const isDomainCheck = await Domain.isAvailable(domain);
    if (isDomainCheck !== "available") {
      if (isDomainCheck === "isPremium") {
        return "domainIsPremium";
      }

      return "domainNotAvailable";
    }

    return true;
  }

  public static validateDomainHandle(
    userInput: UserInput,
    value: unknown,
    hostname: string,
  ): ValidateResult {
    const { name, schema, required = false } = userInput;

    if (!required && !value) {
      return true;
    }

    if (
      name === "email" &&
      typeof value === "string" &&
      hostname.toLowerCase().endsWith(".eu") &&
      value.toLowerCase().endsWith(hostname.toLowerCase())
    ) {
      return "mustNotEndWithOrderedDomainName";
    }

    return validateJsonSchema(value, schema);
  }

  public static async validateTransferAuthentication(
    domain: string,
    authCode?: string,
  ): Promise<AuthCodeValidationResult> {
    const tld = await TldList.getTldByDomain(domain);
    let payload: MittwaldApi.Paths.V2_Domain_Transferable.Post.Parameters.RequestBody =
      { domain };

    if (tld && tld.transferAuthType === "code") {
      if (!authCode) {
        return {
          isValid: false,
          error: "authCodeMissing",
        };
      }
      payload = { domain, authCode };
    }

    const response = await mittwaldApi.domainCheckDomainTransferability.request(
      {
        requestBody: payload,
      },
    );

    assertStatus(response, 200);

    return {
      isValid: response.content.transferable,
      error: Object.keys(response.content.reasons).find(
        (r) =>
          response.content.reasons[r as keyof typeof response.content.reasons],
      ),
    };
  }

  public static async validateDomainForMove(
    domain: string,
    existingDomains: Domain[],
  ): Promise<ValidateResult> {
    const validDomain = await DomainUI.validateDomainForOrder(domain);

    if (
      typeof validDomain === "string" &&
      validDomain !== "domainNotAvailable"
    ) {
      return validDomain;
    }

    const domainAlreadyExists = existingDomains.find((existingDomain) => {
      const parsedDomain = DomainName.parse(domain);
      return existingDomain.domain === parsedDomain.domain;
    });

    if (domainAlreadyExists) {
      return "domainAlreadyRegistered";
    }
    const domainRegistrable = await Domain.isAvailable(domain);
    if (domainRegistrable === "isPremium") {
      return "domainIsPremium";
    }
    if (domainRegistrable === "available") {
      return "domainNotTransferable";
    }

    return true;
  }

  public static validateNameServer(
    nameServer: string,
    currentNameServer: string[],
  ): ValidateResult {
    if (!isDomain(nameServer)) {
      return "isNoDomain";
    }

    if (
      currentNameServer.filter(
        (n) => n.toLowerCase() === nameServer.toLowerCase(),
      ).length > 1
    ) {
      return "isDuplicate";
    }

    return true;
  }
}

export default DomainUI;
