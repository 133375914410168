import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { useFeatureEnabled } from "@mittwald/flow-lib/dist/hooks/useFeatureEnabled";
import { usePathParams } from "@mittwald/flow-lib/dist/hooks/usePathParams";
import { ArrayItemType } from "@mittwald/flow-lib/dist/types/ArrayItemType";
import { mittwaldApi, MittwaldApi } from "../../api/Mittwald";
import { AppInstallationList } from "../app/AppInstallationList";
import Customer from "../customer/Customer";
import { MySqlDatabaseList } from "../database/MySqlDatabaseList";
import Ingress from "../domain/Ingress";
import { DeliveryBoxList } from "../mail/DeliveryBoxList";
import { EmailAddressList } from "../mail/EmailAddressList";
import Order from "../order/Order";
import { Signup } from "../signup/Signup";
import {
  ProjectInviteCreateInputs,
  ProjectInvite,
  ProjectMembership,
  ProjectRoleName,
  ProjectRole,
  ProSpaceProject,
  SpaceServerProject,
} from "./";
import { SubdomainInputs, VHostInputs } from "../domain/Domain";
import ContractItem from "../contract/ContractItem";
import invariant from "invariant";
export enum ProjectDir {
  web = "Web",
  home = "Home",
  logs = "Logs",
}

export type ProjectListItemApiData =
  ArrayItemType<MittwaldApi.Paths.V2_Projects.Get.Responses.$200.Content.Application_Json>;

export type ProjectApiData =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Project_Project;

export type ProjectDisableReason =
  MittwaldApi.Components.Schemas.De_Mittwald_V1_Project_DisableReason;

export interface UpdateProjectDescriptionInput {
  description: string;
}

export interface CreateProjectInput {
  description: string;
  serverId: string;
}

export interface ProSpaceInstallation {
  data: {
    description?: string;
  };
}

export type ProjectUseOrdersOptions =
  MittwaldApi.Paths.V2_Projects_ProjectId_Orders.Get.Parameters.Query;

export class ProjectBase {
  public readonly data: ProjectApiData;
  public readonly id: string;
  public readonly shortId: string;
  public readonly description: string;
  public readonly enabled: boolean;
  public readonly disableReason: ProjectDisableReason | undefined;
  public readonly customerId: string;
  public readonly hostname: string;
  public readonly imageRefId?: string;

  public constructor(data: ProjectApiData) {
    this.data = Object.freeze(data);
    this.id = data.id;
    this.shortId = data.shortId;
    this.enabled = data.enabled;
    this.disableReason = data.disableReason;
    this.customerId = data.customerId;
    this.description = data.description;
    this.imageRefId = data.imageRefId;
    this.hostname = `ssh.${data.clusterID}.${data.clusterDomain}`;
  }

  public useMembership(membershipId: string): ProjectMembership {
    return ProjectMembership.useLoad(this.id, membershipId);
  }

  public useMembershipByPathParam(): ProjectMembership {
    const { membershipId } = usePathParams("membershipId");
    return this.useMembership(membershipId);
  }

  public useMyMembership(): ProjectMembership {
    return ProjectMembership.useLoadOwn(this.id);
  }

  public useMyRole(): ProjectRole {
    return this.useMyMembership().role;
  }

  public useCheckMyRoleIs(role: ProjectRoleName): boolean {
    return this.useMyRole().is(role);
  }

  public useCheckMyRoleIsIn(roles: ProjectRoleName[]): boolean {
    const role = this.useMyRole().name;
    return roles.includes(role);
  }

  public useCustomer(): Customer | undefined {
    const inherited = this.useMyMembership().data.inherited;
    return Customer.useTryLoadById(
      inherited ? this.data.customerId : undefined,
    );
  }

  public useContractItem(): ContractItem {
    if (this instanceof ProSpaceProject) {
      return this.useContract().baseItem;
    }
    if (this instanceof SpaceServerProject) {
      const server = this.useServer();
      if (!server) {
        invariant(server, "space-server project requires server to exist");
      }
      return server.useContract().baseItem;
    }
    invariant(false, "unknown project type");
  }

  public useCustomerCanMakeTransactions(): boolean {
    const customer = this.useCustomer();
    return customer?.isAllowedToOrder ?? false;
  }

  public useCanCreateIngress(): boolean {
    const baseItemTermitionScheduled =
      this.useContractItem().terminationScheduled;

    return this.useCustomerCanMakeTransactions() && !baseItemTermitionScheduled;
  }

  public useEmailAddressList(): EmailAddressList {
    return EmailAddressList.useLoadAllByProjectId(this.id);
  }

  public useDeliveryBoxList(): DeliveryBoxList {
    return DeliveryBoxList.useLoadAllByProjectId(this.id);
  }

  public useDatabaseList(): MySqlDatabaseList {
    return MySqlDatabaseList.useLoadAllByProjectId(this.id);
  }

  public useAppInstallationList(): AppInstallationList {
    return AppInstallationList.useLoadAllByProjectId(this.id);
  }

  public useDefaultIngress(): Ingress | undefined {
    return Ingress.useLoadDefaultForProject(this.id);
  }

  public useProjectIp(): string | undefined {
    return this.useDefaultIngress()?.ipv4Adresses[0];
  }

  public async createIngress(
    values: VHostInputs | SubdomainInputs,
  ): Promise<string> {
    return await Ingress.createNew(values, this.id);
  }

  public async updateDescription(
    data: UpdateProjectDescriptionInput,
  ): Promise<void> {
    const response = await mittwaldApi.projectUpdateProjectDescription.request({
      path: {
        projectId: this.id,
      },
      requestBody: { description: data.description },
    });

    assertStatus(response, 204);
  }

  public getRootDirectory(
    restrictToProjectDirectory?: ProjectDir,
  ): string | undefined {
    if (
      restrictToProjectDirectory &&
      restrictToProjectDirectory in this.data.directories
    ) {
      return this.data.directories[restrictToProjectDirectory];
    }
  }

  public useSshUser(): string {
    const userEmailAddress = Signup.useUserEmailAddress();

    return `${userEmailAddress}@${this.shortId}`;
  }

  public useSshUrl(): string {
    const sshUser = this.useSshUser();

    return `${sshUser}@${this.hostname}`;
  }

  public async deleteAvatar(): Promise<void> {
    const response = await mittwaldApi.projectDeleteProjectAvatar.request({
      path: { projectId: this.id },
    });

    assertStatus(response, 204);
  }

  public async requestAvatarUpload(): Promise<string> {
    const response =
      await mittwaldApi.projectRequestProjectAvatarUpload.request({
        path: {
          projectId: this.id,
        },
      });

    assertStatus(response, 200);

    return response.content.refId;
  }

  public async inviteMember(values: ProjectInviteCreateInputs): Promise<void> {
    await ProjectInvite.createNew(values, this.id);
  }

  public useOrders(opts: ProjectUseOrdersOptions = {}): Order[] {
    const data = mittwaldApi.orderListProjectOrders
      .getResource({
        path: {
          projectId: this.id,
        },
        query: opts,
      })
      .useWatchData();

    return data.map((d) => Order.fromApiData(d));
  }

  public hasContainerAccess(): boolean {
    const showContainerFrontend = useFeatureEnabled("showContainerFrontend");
    return (
      (showContainerFrontend &&
        this instanceof ProSpaceProject &&
        !this.useIsProSpaceLite()) ||
      (showContainerFrontend && this instanceof SpaceServerProject)
    );
  }
}

export default ProjectBase;
